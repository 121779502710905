.loading {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 300;
  color: #ffffff;
  letter-spacing: 2px;
  text-transform: uppercase;
  background: rgba(0, 0, 0, 0.5);

  &:before {
    display: block;
    content: "";
    width: 30px;
    height: 30px;
    border: 0;
    background: #ffffff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    animation: spiner 1s infinite;
  }

  @keyframes spiner {
    0% {
      transform: rotate(0);
      border-radius: 3px;
    }
    50% {
      transform: rotate(180deg);
      border-radius: 50%;
    }
    100% {
      transform: rotate(360deg);
      border-radius: 3px;
    }
  }
}

.center {
  text-align: center;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.anticon-minus-circle {
  font-size: 15px;
  &:hover {
    color: #1890ff;
  }
}

.main-title-wrap {
  height: 40px;
  background: linear-gradient(to left, white, #001528);
  border-radius: 3px;
  margin-bottom: 5px;
  box-shadow: 1px 1px 1px 1px lightgray;
  .main-title {
    font-size: 15px;
    color: #fff;
    line-height: 40px;
    margin-left: 10px;
  }
}

@media all and (max-height: 976px) {
  .main-height {
    height: 93vh;
  }
}

@media (min-height: 1000px) and (max-height: 1100px) {
  .main-height {
    min-height: 93.5vh !important;
  }
}
